import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDrawDown, getOpportunityDetails, getUserDetails, getWallet } from "../apis/apis";
import { useParams } from "react-router-dom";
import { formattedoCurrencyNumbering } from "../../utils/userUtilities";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CardLayout from "../components/layout/CardLayout";

function InvestSIP() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { oppRid } = useParams();

    const [amount, setAmount] = useState(0);
    const [minAmount, setMinAmount] = useState(0);
    const [walletAmount, setWalletAmount] = useState(0);

    const [noOfInstallments, setNoOfInstallments] = useState(0);
    const [investmentAmount, setInvestmentAmount] = useState(0);

    const [isAmountValid, setIsAmountValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAmountChange = (value) => {
        setAmount(value);
        // setIsAmountValid(validateAmount(value));
    };

    const validateAmount = (value) => {
        const rgx = /^[0-9]*\.?[0-9]*$/;
        if(minAmount > value){
            setErrorMessage("Amount below min value");
            return false;
        }
        if(walletAmount < value){
            console.log(walletAmount, value);
            setErrorMessage("Amount exceeded wallet balance");
            return false;
        }
        if(!value.toString().match(rgx)){
            setErrorMessage("Invalid Amount");
            return false;
        }
        return true;
    };

    const fetchuserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { invPref, investorSip, firstPayment } = userDetails.data;

            setNoOfInstallments(investorSip.totalInstallments);
            setInvestmentAmount(investorSip.installmentAmount);
        }
    };

    const getWalletData = async () => {
        const walletData = await getWallet();

        if (walletData.status) {
            const balanceAmt = walletData?.data?.balanceAmt ?? 0;
            setWalletAmount(balanceAmt);
        }
    }

    const handleDrawDown = () => {
        setIsLoading(true);
        getDrawDown({
            "oppRid": oppRid, "amount": amount, "redirectUrl": window.location.origin + "/user/drawdown-status", "tranType": process.env.REACT_APP_TYPE_WALLET_OPPORTUNITY
        }).then((data) => {
            if(data.status){
                window.open(data.data.url, "_self");
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    const handleProceed = () => {

        const status = validateAmount(amount);
        setIsAmountValid(status);
        // if(isAmountValid){
        if(status){
            handleDrawDown();
        }
    };

    const fetchOpportunityDetails = async () => {
        const opportunityDetails = await getOpportunityDetails({oppRid: oppRid});

        if (opportunityDetails.status) {
            const { minAmount } = opportunityDetails.data;
            setMinAmount(minAmount);
            handleAmountChange(minAmount);
        }
    }

    useEffect(() => {
        getWalletData();
        fetchuserData();
        fetchOpportunityDetails();
    }, []);

    return (
        <CardLayout showBackButton={false}>

            <div className="container mt-5">
                <h3 className="title-md text-center mb-4">Monthly SIP Investment</h3>

                <div className="card-raised mx-auto w-75 p-4 mb-5">
                    {/* <h3 className="card-title-md text-center mb-4">You have selected to invest<br />INR {investmentAmount}/- for {noOfInstallments} months</h3> */}
                    <h3 className="card-title-md text-center mb-4">How much do you wish to<br />invest in project name ?</h3>

                    <div className="form-outline w-50 mx-auto mb-4">
                        <div className="text-center mb-4">
                            <span className="label-rounded-filled">Wallet Amount: {formattedoCurrencyNumbering(walletAmount)}/-</span>
                            <AddCircleIcon className="ms-2" onClick={() => history.push("/user/wallet-topup")} />
                        </div>
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Amount"
                            onChange={(e) => handleAmountChange(e.target.value)}
                            value={amount}
                            id="amount"
                        />
                        {!isAmountValid ? <p className="text-danger">{errorMessage}</p> : ''}
                        <h5 className="card-text mt-3 mb-4">Min INR {formattedoCurrencyNumbering(minAmount)}/-</h5>
                    </div>

                    {/* <div className="w-50 mx-auto">
                        <div className="text-center">
                            <span className="label-rounded-filled">{investmentAmount}/-</span>
                        </div>
                        <h5 className="text-center mt-5">Period of {noOfInstallments} months</h5>
                    </div> */}
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <button className="color-btn mb-5" onClick={handleProceed} disabled={isLoading}>
                    {isLoading ? "Loading..." : "PROCEED"}
                </button>
            </div>
        </CardLayout>
    );
}

export default InvestSIP;