import React, {useEffect} from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useHistory, useLocation } from 'react-router-dom';
import CardLayout from '../components/layout/CardLayout';

const SuccessLP = () => {
    const history = useHistory();
    const location = useLocation();
    const stateParams = new URLSearchParams(location.state);
    const alertTitle = stateParams.get('alertTitle');

    useEffect(() => {
        localStorage.clear();
        if(!alertTitle){
            history.replace("/");
        }
    }, []);

  return (
    <CardLayout showBackButton={false}>
         <div className="container mt-5">
                <div className="d-flex gap-3 align-items-center flex-column py-5">
                <CheckCircleRoundedIcon sx={{ fontSize: 80 }} color="success" />
                <h3 className="title-md">{alertTitle}</h3>
                </div>
            </div>
    </CardLayout>
  )
}

export default SuccessLP;