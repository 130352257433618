import { apiGetPartnerId } from "../userside/apis/apis";

export const fetchPartnerIdFromApi = async (setPartnerId, setPartner_Id, showSnackbar) => {
    try {
      const { partnerId } = await apiGetPartnerId();
  
      setPartnerId(partnerId);
  
      // Set partnerId in localStorage
      setPartner_Id(partnerId);

    } catch (err) {
      console.error('Error fetching Partner ID:', err);
      showSnackbar(err); 
    }
  };
  