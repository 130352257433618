import React, { useEffect, useState } from 'react';
import AuthLayout from '../../components/layout/AuthLayout';
import { useHistory } from 'react-router-dom';
import { apiSaveBasicDetail, getCityNames, getCountryNames, getDataDictionaryValues, getUserDetails } from '../../apis/apis';
import { useSnackbar } from '../../../context/SnackbarContext';
import { DD_TYPE_INVESTOR_TYPE } from '../../../utils/constants/constants';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField } from '@mui/material';
import { use } from 'react';

const CommunicationDetails = () => {
    let history = useHistory();
    const { showSnackbar } = useSnackbar();

    const [data, setData] = useState({
        fullName: "",
        dobDoi: "",
        investorType: null,
        address: "",
        countryRid: "",
        cityRid: "",
        stateRid: "",
        countryName: "",
        cityName: "",
        countryCode: "",
    });
    const [investorTypeList, setInvestorTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [initialCityList, setInitialCityList] = useState([]);

    const handle = (e) => {
        const { id, value } = e.target;
        const newData = { ...data };

        // Parse `countryRid` as an integer
        if (id === 'countryRid') {
            newData[id] = parseInt(value, 10); // Parse to integer
        } else {
            newData[id] = value;
        }

        setData(newData);
    };

    const fetchUserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { address, fullName, dobDoi, investorType,countryCode, cityRid, countryRid, stateRid, countryName, cityName } = userDetails.data;

            setData({
                fullName: fullName,
                dobDoi: dobDoi?.split(' ')[0],
                investorType: investorType,
                address: address,
                cityRid: cityRid,
                countryRid: countryRid,
                stateRid: stateRid,
                countryName: countryName,
                cityName: cityName,
                countryCode: countryCode,
            });

            if (cityName) {
                getCities(countryRid, cityName);
            }
            // // Fetch cities for the selected country
            // if (countryRid) {
            //     getCities(countryRid);
            // }
        }
    };


    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const invTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_INVESTOR_TYPE
                );
                setInvestorTypeList(invTypes);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getCountries = () => {
        const countryCode = data.countryCode || localStorage.getItem("dialCode");
        getCountryNames(countryCode)
            .then((res) => {
                setCountryList(res);
                setData({
                    ...data,
                    countryRid: res[0].countryRid,
                })
                if(data.cityName === null || data.cityName === ""){
                    getCities(res[0].countryRid);
                }
            })
            .catch((error) => {
                console.error('Error fetching countries:', error);
            });
    };

    const submit = (e) => {
        e.preventDefault();

        if (!data?.dobDoi || data.dobDoi.trim() === "") {
            showSnackbar("Please select DOB/DOI");
            return;
        }

        if (!data.investorType) {
            showSnackbar("Please select investor type");
            return;
        }

        if (!data.address?.trim()) {
            showSnackbar("Please enter an address");
            return;
        }

        if (!data.countryRid) {
            showSnackbar("Please select a country");
            return;
        }

        if (!data.cityRid) {
            showSnackbar("Please select a city");
            return;
        }

        console.log(data)
        setIsLoading(true);

        apiSaveBasicDetail(data)
            .then(() => {
                history.replace("/user/bank-details");
            })
            .catch((err) => {
                showSnackbar(err);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchUserData();
        getDataDictionary();
        getCountries();
    },[]);

    const getCities = (countryRid = data.countryCode, searchQuery = "") => {
        
        getCityNames(countryRid, 1, 50, searchQuery)
            .then((data) => {
                setCityList(data || []);
                setInitialCityList(data || []);
            })
            .catch((e) => {
                console.error('Error fetching cities:', e);
            });
    };

    // useEffect(() => {
    //     if (data.countryRid) {
    //         getCities(data.countryRid);
    //     }
    // }, [data.countryRid]);

    useEffect(() => {
        let timeoutId;

        if (searchQuery.length >= 3 && data.countryRid) {
            const cityExists = cityList.some((city) =>
                city.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            if (!cityExists) {
                timeoutId = setTimeout(() => {
                    getCityNames(data.countryRid, 1, 20, searchQuery)
                        .then((data) => {
                            setCityList(data || []);
                        })
                        .catch((e) => {
                            console.error('Error fetching filtered cities:', e);
                        });
                }, 2000);
            } else {
                setCityList(cityList.filter((city) =>
                    city.name.toLowerCase().includes(searchQuery.toLowerCase())
                ));
            }
        } else {
            setCityList(initialCityList);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchQuery, data.countryRid, initialCityList, cityList]);

    return (
        <AuthLayout title="Other Details">
            <form onSubmit={submit}>

                <div className="form-floating mb-4 mt-2">
                    <input
                        className="form-control"
                        placeholder="DOB"
                        name="dobDoi"
                        type="date"
                        value={data.dobDoi || ""}
                        id="dobDoi"
                        onChange={handle}
                    />
                    <label htmlFor="dobDoi">DOB</label>
                </div>

                <div className="form-floating mb-4">
                    <select
                        className="form-select"
                        id="investorType"
                        name="investorType"
                        onChange={handle}
                        value={data.investorType}
                        aria-label="Investor Type"
                    >
                        <option value={null}></option>
                        {investorTypeList?.map((item) => (
                            <option key={item.ddIndex} value={item.ddIndex}>
                                {item.ddValue}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="investorType">Investor Type</label>
                </div>

                <div className="form-floating mb-4">
                    <input
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        value={data.address}
                        id="address"
                        onChange={handle}
                    />
                    <label htmlFor="address">Address</label>
                </div>

                <div className="form-floating mb-4">
                    {countryList?.length > 0 ? (
                        <select
                            className="form-select"
                            id="countryRid"
                            name="countryRid"
                            onChange={handle}
                            value={data.countryRid || ''}
                            aria-label="Select Country"
                        >
                            <option value={null}></option>
                            {countryList?.map((country) => (
                                <option key={country.countryRid} value={country.countryRid}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <div className="form-control"></div>
                    )}
                    <label htmlFor="countryRid">Country</label>
                </div>


                <div className="form-floating mb-4">
                    <Autocomplete
                        options={cityList}
                        getOptionLabel={(option) => option.name || ''}
                        isOptionEqualToValue={(option, value) => option.cityRid === value.cityRid}
                        value={cityList.find((city) => city.cityRid === data.cityRid) || null}
                        onInputChange={(event, value) => setSearchQuery(value)}
                        onChange={(event, value) =>
                            setData({
                                ...data,
                                cityRid: value ? value.cityRid : '',
                                stateRid: value ? value.stateRid : '',
                            })
                        }
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    {option.name}
                                    {option.stateName && ` [${option.stateName}]`}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="City"
                                placeholder="Search your city"
                                variant="outlined"
                            />
                        )}
                        disablePortal
                        fullWidth
                    />

                </div>

                <div className="d-flex mt-4 mb-2">
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
                        Save & Continue
                    </button>
                </div>
            </form>
        </AuthLayout>
    );
};

export default CommunicationDetails;
