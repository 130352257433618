import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import InvestmentContainer from "../components/InvestmentContainer";
import Graph from "../components/Graph";
import Footer from "../components/Footer";
import { getDashboard, getUserDetails } from "../apis/apis";
import DashboardContent from "../components/DashboardContent";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import Opportunites from "./Opportunities";
import AllActivity from "./AllActivity";
import { COMPANY_PDP, getDashboardTab, getItem, LS_INV_CODE, setDashboardTab } from "../../utils/LocalStorageUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Commitments from "./Commitments";

const tabStyle = {
  borderBottom: "1px solid rgba(0, 0, 0, 0.175)",
};

function HomePage(props) {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [investmentByRounds, setInvestmentByRounds] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const handleTab = (value) => {
    setTabIndex(value);
    setDashboardTab(value?.toString());
  };

  const fetchUserData = async () => {
    const userDetails = await getUserDetails();
    if (userDetails.status) {
      const {
        mcaStatus, ckycStatus, investorSip,
        fullName, email, mobile, countryCode,
        dobDoi, address, investorType, cityRid, countryRid,
        bankName, bankNameIndex, bankAddress, accountNo, ifsc
      } = userDetails.data;

      if (!ckycStatus) {
        history.push("/user/successfull");
      } else if (
        (dobDoi === null || dobDoi === "") ||
        (address === null || address === "") ||
        (investorType === null || investorType === "") ||
        (cityRid === 0 ) || (countryRid === 0)
      ) {
        history.push("/user/communication-details");
      } else if (
        ((bankName === '' || bankName === null) && (bankNameIndex === 0 || bankNameIndex === null)) ||
        (bankAddress === '' || bankAddress === null) ||
        (accountNo === '' || accountNo === null) ||
        (ifsc === '' || ifsc === null)
      ) {
        history.push("/user/bank-details");
      } 
      // else if (mcaStatus === 0) {
      //   history.push("/user/successfull");
      // }

      localStorage.setItem("fullName", fullName);
      localStorage.setItem("email", email);
      localStorage.setItem("mobileNumber", mobile);
      localStorage.setItem("countryCode", countryCode);
      localStorage.setItem("dialCode", countryCode);
      localStorage.setItem(LS_INV_CODE, userDetails?.data.invCode);

      if (!ckycStatus) {
        setAlertMessage("Complete your CKYC process");
      } else if (!mcaStatus !== 0) {
        setAlertMessage("Complete your MCA process");
      } else if (
        investorSip !== null &&
        investorSip?.enachStatus !== process.env.REACT_APP_ENACH_STATUS_INPROGRESS
      ) {
        setAlertMessage("Complete your SIP E-Nach process");
      }

      setShowAlertBar(
        !ckycStatus ||
        !mcaStatus ||
        (investorSip !== null &&
          investorSip?.enachStatus !== process.env.REACT_APP_ENACH_STATUS_INPROGRESS)
      );
    }
  };

  const getDashboardData = async () => {
    const dashboardData = await getDashboard();

    if (dashboardData.status) {
      const {
        invByRoundList,
        graphList,
        noOfInvestments,
        noOfOpportunity,
        portfolioValue,
        totalInvestedAmount,
        multiple,
      } = dashboardData.data;
      setInvestmentByRounds(invByRoundList);
      setGraphData(graphList);
      setCardData({
        noOfInvestments,
        noOfOpportunity,
        portfolioValue,
        totalInvestedAmount,
        multiple,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (getItem(COMPANY_PDP)) {
      history.replace("/user/company-pdp/" + parseInt(getItem(COMPANY_PDP)));
    }

    fetchUserData();
    getDashboardData();

    if (getDashboardTab() !== null) {
      if (parseInt(getDashboardTab()) === 0) {
        setDashboardTab("0");
      } else {
        setTabIndex(parseInt(getDashboardTab()));
      }
    }
  }, []);

  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center position-absolute"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : (
        <div className="vh-100 d-flex flex-column">
          <NavBar />
          <div className="container-fluid flex-grow-1 overflow-auto">
            <div className="container mx-auto px-3">
              <Box className="mt-4" style={tabStyle}>
                <Tabs value={tabIndex} onChange={(e, v) => handleTab(v)}>
                  <Tab value={0} label="Dashboard" className="fs-med-medium" />
                  <Tab value={1} label="Opportunities" className="fs-med-medium" />
                  <Tab value={3} label="Commitments" className="fs-med-medium" />
                  <Tab value={2} label="Activity" className="fs-med-medium" />
                </Tabs>
              </Box>

              {tabIndex === 0 && (
                <>
                  <Graph cardData={cardData} graphData={graphData} />
                  <DashboardContent
                    handleTab={handleTab}
                    investmentByRounds={investmentByRounds}
                  />
                  <InvestmentContainer />
                </>
              )}

              {tabIndex === 1 && <Opportunites />}
              {tabIndex === 2 && <AllActivity />}
              {tabIndex === 3 && <Commitments />}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default HomePage;
